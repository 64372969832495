const Report = () => import(/* webpackPrefetch: true */ '@/views/private/report/Index.vue')

const routes = [
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: {
      title: "Report"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})